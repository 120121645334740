import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Search from "./components/Search";
import NotFound from "./components/NotFound";

class App extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/" component={Search} />
          <Route path="/not-found" component={NotFound} />
          <Redirect to="/not-found" />
        </Switch>
      </div>
    );
  }
}

export default App;
